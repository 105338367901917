import React from 'react';
import './App.css';
import MachineLearningHistory from './MachineLearningHistory';

function App() {
  return (
  
    <div className="App">
      <header className="App-header">
        
        <h2>
        Exploring the Future: A Deep Dive into Application of Generative Artificial Intelligence in Marketing Technologies
        </h2>
        
      </header>
      <h3>Generative AI is a groundbreaking leap in technology, revolutionizing the way we approach innovation</h3>
      <p>There are so many exciting opportunities for businesses to enhance their marketing efforts using generative artificial intelligence technology. By leveraging this technology, businesses can make strides toward achieving their goals. </p>

      <p>Isn't it fascinating to think about how we ended up here?</p>
      <MachineLearningHistory />
      
      <p>What's next? Let's explore current advancements and delve into using large language models to boost the return on investment in marketing efforts in the near future. </p>
    
      

    

    </div>
    
   
    
  );
}

export default App;
