import React from 'react';

function MachineLearningHistory() {
  return (
    <div>
      <h4>Development of Machine Learning Models</h4>
      <p>Lets look at it as 3 historical developments.<br/> 
                Development 1 -  Development of Machine Learning Models<br/>
                Development 2 -  Development of Natural Language Processing Models<br/>
                Development 3 -  Development of Applications using Natural Language Processing Models
    </p>
    <h5>Development 1 -  Development of Machine Learning Models</h5>
    
      <p>The development of models for various machine learning tasks began to accelerate in the late 20th century and has continued to evolve rapidly in the 21st century. Here are some key milestones:</p>

      <h5>1950s-1960s:</h5>
      <p>The field of artificial intelligence (AI) emerged, and early work on machine learning began. Some notable developments include the perceptron algorithm (1957) and the development of decision tree algorithms.</p>

      <h5>1970s-1980s:</h5>
      <p>During this period, there was significant research in areas such as neural networks, genetic algorithms, and expert systems. The backpropagation algorithm for training neural networks was also developed in the 1980s.</p>

      <h5>1990s:</h5>
      <p>The 1990s saw the rise of more practical machine learning applications, including the development of support vector machines (SVMs) and the introduction of ensemble methods like random forests.</p>

      <h5>2000s:</h5>
      <p>In the 2000s, there was a surge of interest in machine learning fueled by the availability of large datasets and improvements in computing power. This led to advancements in areas such as deep learning, reinforcement learning, and probabilistic graphical models.</p>

      <h5>2010s-Present:</h5>
      <p>The 2010s marked a period of rapid progress in machine learning, driven by deep learning techniques and the availability of big data. Breakthroughs in image recognition, speech recognition, and autonomous driving, among others, have been achieved using deep learning models.</p>

      <p>Overall, the development of models for machine learning tasks outside of NLP has been a continuous process, with advancements building upon earlier work and driven by improvements in algorithms, data availability, and computational resources.</p>
      <h5>Development 2 -  Development of Natural Language Processing Models</h5>
      <p>The development of models for natural language processing (NLP) and machine learning began several decades ago, but significant advancements in the field, particularly in deep learning and neural networks, have occurred more recently.</p>
   
    <h5>1950s-1980s:</h5>
    <p> Early efforts in NLP focused on rule-based systems and symbolic approaches, such as the development of programs like ELIZA (1960s) and SHRDLU (1970s).</p>

    <h5>1990s:</h5>
    <p>The 1990s saw a shift towards statistical approaches, with the development of models like Hidden Markov Models (HMMs) and probabilistic context-free grammars (PCFGs) for speech recognition and machine translation.</p>

    <h5>2000s:</h5>
    <p>In the 2000s, there was increased interest in machine learning approaches for NLP, including support vector machines (SVMs) and conditional random fields (CRFs). This period also saw the emergence of more data-driven approaches and the use of larger datasets for training.</p>

    <h5>2010s:</h5>
    <p>The 2010s marked a significant shift towards deep learning in NLP, driven by advancements in neural network architectures, such as recurrent neural networks (RNNs), long short-term memory (LSTM) networks, and transformer models. This led to breakthroughs in tasks like language modeling, machine translation, and sentiment analysis.</p>

    <h5>2010s-Present:</h5>
    <p>One of the most notable developments in recent years is the release of the GPT (Generative Pre-trained Transformer) series of models by OpenAI, starting with GPT-1 in 2018 and culminating in GPT-3 in 2020. These models have demonstrated remarkable capabilities in natural language understanding and generation, showcasing the power of large-scale pre-training and fine-tuning.</p>

    <p>Overall, the field of NLP and machine learning has seen rapid evolution and growth, driven by advances in algorithms, computing power, and the availability of large datasets</p>

    <p>That brings us to look a short History Of ChatGPT just to look at how we got to where we are today</p>
    <h5>Development 3 -  Development of Applications like ChatGPT using Natural Language Processing Models </h5>
    <h5>Origin (2018): </h5>
    <p>ChatGPT is based on OpenAI's GPT (Generative Pre-trained Transformer) architecture, first introduced in 2018. It was trained on a vast amount of text from the internet to understand and generate human-like text.</p>
    <h5>Early Iterations (2019-2020):  </h5>
    <p>In the following years, improvements were made to the model's architecture and training methods, enhancing its ability to generate more coherent and contextually relevant responses.</p>
    <h5>Release of GPT-3 (2020):  </h5>
    <p>OpenAI released GPT-3 in 2020, the model behind ChatGPT, which significantly increased the size and complexity of the model, allowing for more nuanced and informative responses.</p>
    <h5>Continuous Improvement(2021-2024):  </h5>
    <p>Since its release, ChatGPT has been continuously improved through fine-tuning, updates to its training data, and enhancements to its underlying algorithms. These improvements have led to better conversational abilities and more accurate responses over time.</p>
    <h5>Future Trends:  </h5>
    <p>Looking ahead, ChatGPT is expected to continue improving with advancements in AI research, potentially leading to even more human-like interactions and better understanding of user queries and context.</p>
    <p>Please note that the details provided are a general overview and not specific to any particular year or trend.</p>
    <p>There are many other LLMs and applications and we will pick and deep dive into applications that help business improve their marketing return on investment</p>
  </div>
  );
}

export default MachineLearningHistory;
